import { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { timeZoneArray } from 'utils/selectArrays';
import $user from 'signals/User.signals';
import ZipInput from 'components/global/Inputs/ZipInput';
import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';
import SelectInput from 'components/global/Inputs/SelectInput';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import {
  fetchOrganizationData,
  handleBrowse,
  uploadCompanyLogo,
  updateOrganizationData,
  deleteCompanyLogo,
} from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.helpers';
import { $settingsOrganization } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.signals';
import RoundedImage from 'components/global/RoundedImage';
import Loadable from 'components/global/Loadable';
import { US_STATES_WITH_LABELS } from 'utils/validation';
import ProfilePicUpload from 'components/global/ProfileCircle';

const CompanyInfo = () => {
  const {
    companyLogo,
  } = $settingsOrganization.value;
  const { currentOrganization } = $user.value;
  useEffect(async () => {
    if (currentOrganization) {
      await fetchOrganizationData(currentOrganization.id);
    }
  }, [currentOrganization]);

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 bg-gray-50 rounded">
        <Col sm={3} className="p-0 h-100">
          <div className="cursor-pointer h-100 my-16" role="button" tabIndex={0} onClick={() => handleBrowse()}>
            <input
              id="file-input"
              className="d-none"
              type="file"
              accept="image/*"
              onChange={(e) => uploadCompanyLogo(e, $user.value.currentOrganization.id)}
            />
            {companyLogo ? (
              <Loadable template="profilePic" signal={$settingsOrganization}>
                <RoundedImage src={companyLogo} />
              </Loadable>
            ) : (
              <ProfilePicUpload title="Logo" />
            )}
          </div>
        </Col>
        <Col sm={9} className="p-0 my-auto py-32">
          <h2 className="fw-700 mb-8">Company Logo</h2>
          <p className="fw-400 mb-0">Upload your logo here</p>
          {companyLogo && (
            <Button
              variant="outline-danger"
              onClick={() => deleteCompanyLogo($user.value.currentOrganization.id)}
              className="w-auto mt-8 btn-sm"
            >
              Delete Logo
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mx-0 mt-24 py-24 bg-gray-50 rounded">
        <Form.Group>
          <h2 className="fw-700 mb-16">Company Info</h2>
          <Form.Label htmlFor="companyName"><strong>Company Name</strong></Form.Label>
          <UniversalInput
            placeholder="Company Name"
            className="mb-16"
            name="companyName"
            signal={$settingsOrganization}
          />
          <Form.Label htmlFor="companyAddressLine1"><strong>Address</strong></Form.Label>
          <UniversalInput
            placeholder="Address"
            className="mb-16"
            name="companyAddressLine1"
            signal={$settingsOrganization}
          />
          <Row className="m-0">
            <Col sm={6} className="p-0 pe-8">
              <Form.Label htmlFor="companyAddressLine2"><strong>Suite # (optional)</strong></Form.Label>
              <UniversalInput
                placeholder="Suite #"
                className="mb-16"
                name="companyAddressLine2"
                signal={$settingsOrganization}
              />
            </Col>
            <Col sm={6} className="p-0 ps-8">
              <Form.Label htmlFor="companyAddressCity"><strong>City</strong></Form.Label>
              <UniversalInput
                placeholder="City"
                className="mb-16"
                name="companyAddressCity"
                signal={$settingsOrganization}
              />
            </Col>
          </Row>
          <Row className="m-0">
            <Col sm={6} className="p-0 pe-8">
              <Form.Label htmlFor="companyAddressState"><strong>State</strong></Form.Label>
              <SelectInput
                className="w-100 mb-16"
                name="companyAddressState"
                signal={$settingsOrganization}
                options={US_STATES_WITH_LABELS()}
              />
            </Col>
            <Col sm={6} className="p-0 ps-8">
              <Form.Label htmlFor="companyAddressZipCode"><strong>Zip code</strong></Form.Label>
              <ZipInput
                name="companyAddressZipCode"
                value={$settingsOrganization.value.companyAddressZipCode}
                signal={$settingsOrganization}
                className="mb-16"
              />
            </Col>
          </Row>
          <Form.Label htmlFor="phoneNumber"><strong>Phone Number</strong></Form.Label>
          <PhoneNumberInput
            name="phoneNumber"
            className="mb-16"
            value={$settingsOrganization.value.phoneNumber}
            signal={$settingsOrganization}
          />
          <Form.Label htmlFor="primaryContact"><strong>Time Zone</strong></Form.Label>
          <SelectInput
            name="timeZone"
            placeholder="Time zone"
            options={timeZoneArray()}
            className="mb-40"
            signal={$settingsOrganization}
          />
        </Form.Group>
      </Row>
      <Row className="mx-0 px-0 my-24">
        <Col className="ps-0">
          <Button
            variant="outline-primary"
            className="w-100 mb-16"
            onClick={() => fetchOrganizationData($user?.value?.currentOrganization?.id)}
          >
            CANCEL
          </Button>
        </Col>
        <Col className="pe-0">
          <Button
            variant="primary"
            className="w-100 mb-16"
            onClick={() => updateOrganizationData($user?.value?.currentOrganization?.id)}
          >
            SAVE CHANGES
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default CompanyInfo;

import { Container, Row, Col, Button, Image, Dropdown, Placeholder } from 'react-bootstrap';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ContentWrapper from 'components/global/ContentWrapper';
import Money from 'components/global/Money';
import Pagination from 'components/global/Pagination';
import filter from 'images/filter-alt.svg';
import vendorsArrow from 'images/vendorsArrow.svg';
import vendorsEmpty from 'images/vendorsEmpty.svg';
import useWindowSize from 'hooks/useWindowSize';
import VendorDetailTabs from 'components/views/Vendors/_components/VendorDetailTabs';
import VendorDetailModal from 'components/views/Vendors/_components/VendorDetailModal';
import VendorConnectionModal from 'components/views/Vendors/_components/VendorConnectionModal';
import SortingArrow from 'components/views/Bills/_components/SortingArrow';
import Loadable from 'components/global/Loadable';
import logEvent from 'utils/logEvent';
import useEffectAsync from 'utils/useEffectAsync';
import VendorsSearch from 'components/views/Vendors/_components/VendorsSearch';
import VendorsAutoComplete from 'components/views/Vendors/_components/VendorsAutoComplete';
import { $syncing } from 'signals/Global.signals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';
import VendorCard from './_components/VendorCard';
import {
  fetchAndSetVendors,
  fetchAndSetAutocomplete,
  handleSelectVendor,
  handleSelectSorting,
} from './_helpers/Vendors.helpers';
import {
  $vendors,
  $vendorsFilter,
  $selectedVendor,
  $vendorsUI,
} from './_helpers/Vendors.signals';

const Vendors = () => {
  const history = useHistory();
  const { isBreakpointDown } = useWindowSize();
  const { selectedSorting, page, perPage, search } = $vendorsFilter.value;
  const { name, balance, latest_acct_provider_balance: acctProviderBalance, bill_summary: billStatement } = $selectedVendor.value;
  const { items, totalCount } = $vendors.value;

  useEffect(() => {
    logEvent({ name: 'view_all_vendors' });
  }, []);

  useEffect(() => {
    $vendorsFilter.update({ page: 1 });
  }, [selectedSorting]);
  useEffectAsync(fetchAndSetVendors, [page, selectedSorting]);
  useEffectAsync(fetchAndSetAutocomplete, [search], 400);

  return (
    <ContentWrapper>
      <Container fluid className="px-lg-40">
        <Row>
          <Col xs={12} lg={5} className="left-panel-border pt-32 pt-lg-88 px-0">
            <Row className="mr-24 mb-md-32 pe-lg-24">
              <Col className="d-flex justify-content-between align-items-center">
                <h1 style={{ fontSize: '40px' }} className="fw-400 mb-0">Vendors</h1>
                <Button
                  variant="info"
                  className="mb-8 circle-30 d-flex align-items-center justify-content-center"
                  onClick={() => history.push('/vendors/add')}
                >
                  <FontAwesomeIcon icon={faPlus} width={12} />
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center mb-md-24 pe-lg-24">
              <Col xs={11}>
                <div className="position-relative">
                  <VendorsSearch />
                  <VendorsAutoComplete />
                </div>
              </Col>
              <Col xs={1} className="ps-0">
                <div className="d-flex justify-content-end cursor-pointer">
                  <Dropdown>
                    <Button
                      variant="eye"
                      className="p-0 m-0 text-secondary h-100"
                    >
                      <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle p-0 m-0 h-100">
                        <Image height={16} src={filter} />
                      </Dropdown.Toggle>
                    </Button>
                    <div className="float-right">
                      <Dropdown.Menu align="start" className={`${!isBreakpointDown('md') && 'custom-nav-dropdown'} p-0`}>
                        <Dropdown.Header>Sort By</Dropdown.Header>
                        <Dropdown.Item onClick={() => handleSelectSorting('latest_acct_provider_balance')} className="rounded">
                          Open Balance <SortingArrow signal={$vendorsFilter} field="latest_acct_provider_balance" />
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectSorting('name')} className="rounded">
                          Name <SortingArrow signal={$vendorsFilter} field="name" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Loadable template="component" signal={$vendors}>
              <>
                {items && !items.length && (
                  <Row className="mx-0 p-0">
                    <Image className="w-100 px-0" src={vendorsEmpty} />
                  </Row>
                )}
                <div className="overflow-scroll-y" style={{ height: '60vh' }}>
                  {items?.map(vendor => (
                    <Row key={vendor?.id} className="mx-0 pe-24">
                      <VendorCard
                        active={vendor.id === $selectedVendor.value.id}
                        item={vendor}
                        onSelect={() => handleSelectVendor({ vendor, isBreakpointDown })}
                        handleClickBills={() => $vendorsUI.update({ openTab: 'bills' })}
                        handleClickProjects={() => $vendorsUI.update({ openTab: 'projects' })}
                      />
                    </Row>
                  ))}

                </div>
                <Pagination
                  className="pe-24"
                  itemsPerPageAmount={perPage}
                  paginationMaxButtonAmount={5}
                  totalItemsCount={totalCount}
                  currentPageItemsCount={items?.length}
                  currentPage={page}
                  setCurrentPage={(value) => $vendorsFilter.update({ page: value })}
                />
              </>
            </Loadable>
          </Col>
          <VendorDetailModal />
          <Col lg={7} className="ps-24 mt-40 d-none d-lg-block vh-100 overflow-y-auto">
            {name ? (
              <Loadable template="component" signal={$selectedVendor}>
                <Row className="mx-0 mt-48">
                  <Col className="d-flex justify-content-between">
                    <h1>{name}</h1>
                  </Col>
                </Row>
                <Row className="mx-0 px-56 py-32 mb-24 mt-48 mb-56 bg-gray-50" style={{ borderRadius: '15px' }}>
                  <Col>
                    <div>
                      <p className="lead fw-800 text-primary">Balance:</p>
                      {$syncing.value.isSyncing ? (
                        <Placeholder as="div" animation="glow" className="mb-0 w-100">
                          <Placeholder xs={12} as="h1" className="text-loadable" />
                        </Placeholder>
                      ) : (
                        <h1><Money amount={acctProviderBalance !== null ? acctProviderBalance : balance} /></h1>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p className="lead fw-800 text-primary">Overdue:</p>
                      {$syncing.value.isSyncing ? (
                        <Placeholder as="div" animation="glow" className="mb-0 w-100">
                          <Placeholder xs={12} as="h1" className="text-loadable" />
                        </Placeholder>
                      ) : (
                        <h1><Money amount={billStatement.overdue_amount} /></h1>
                      )}
                    </div>
                  </Col>

                </Row>
                <Row className="d-flex align-items-center">
                  <Col>
                    <VendorDetailTabs />
                  </Col>
                </Row>
              </Loadable>
            ) : (
              <>
                <Image src={vendorsArrow} height="150" />
                <h2 className="text-primary fw-700 text-center">
                  Let&apos;s get some vendors added so that you can start working together!
                </h2>
                <ThemeImage name="empty_vendor" className="h-auto w-75 mt-24 d-flex mx-auto" />
              </>
            )}
          </Col>
        </Row>
      </Container>
      <VendorConnectionModal />
    </ContentWrapper>
  );
};

export default Vendors;

import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import ContentWrapper from 'components/global/ContentWrapper';
import useWindowSize from 'hooks/useWindowSize';
import { $settings, hasPermission } from 'components/views/Settings/Settings.helpers';
import SettingsOrganization from 'components/views/SettingsOrganization/SettingsOrganization';
import SettingsReminders from 'components/views/SettingsReminders/SettingsReminders';
import SettingsPayment from 'components/views/SettingsPayment/SettingsPayment';
import SettingsLogs from 'components/views/SettingsLogs/SettingsLogs';
import organizationSettingsImg from 'images/organizationSettings.svg';
import invoiceReminderSettingsImg from 'images/invoiceReminderSettings.svg';
import paymentSettingsImg from 'images/paymentSettings.svg';
import connectionsSettingsImg from 'images/connectionsSettings.svg';
import logsSettingsImg from 'images/logsSettings.svg';
import SettingsConnections from 'components/views/SettingsConnections';
import SettingsModal from 'components/views/Settings/_components/SettingsModal';
import logEvent from 'utils/logEvent';
import SettingsAccounting from 'components/views/SettingsAccounting';

const settingTabs = [
  {
    id: 1,
    route: 'organization',
    tab: 'organization-settings',
    icon: organizationSettingsImg,
    title: 'Organization',
    description: 'Update company info, users...',
    component: <SettingsOrganization />,
    permissionsRequired: ['ADMIN'],
  },
  {
    id: 2,
    route: 'reminders',
    tab: 'invoice-reminder-settings',
    icon: invoiceReminderSettingsImg,
    title: 'Invoice Reminders',
    description: 'Update invoice reminders, overdue notifications...',
    component: <SettingsReminders />,
  },
  {
    id: 3,
    route: 'payments',
    tab: 'payment-settings',
    icon: paymentSettingsImg,
    title: 'Payments',
    description: 'Update payment methods...',
    component: <SettingsPayment />,
  },
  {
    id: 4,
    route: 'sync',
    tab: 'sync-settings',
    icon: logsSettingsImg,
    title: 'Accounting Software',
    description: 'Update Sync Settings',
    component: <SettingsAccounting />,
  },
  {
    id: 5,
    route: 'connections',
    tab: 'connections-settings',
    icon: connectionsSettingsImg,
    title: 'Connections',
    description: 'View and manage connection requests',
    component: <SettingsConnections />,
  },
  {
    id: 6,
    route: 'logs',
    tab: 'logs-settings',
    icon: logsSettingsImg,
    title: 'Logs',
    description: 'View sync history',
    component: <SettingsLogs />,
  },
];

const Settings = () => {
  const history = useHistory();
  const { isBreakpointDown } = useWindowSize();
  const isMobile = isBreakpointDown('md');
  const { currentTab } = $settings.value;

  useEffect(() => {
    const found = settingTabs.find(obj => obj.route === history.location.pathname.replace('/settings/', ''));
    if (found) {
      logEvent(`view_settings_${found.tab}`);
      $settings.update({
        ...$settings.value,
        currentTab: found.tab,
        isMobileModalOpen: isMobile,
      });
    }
  }, [history.location.pathname, isMobile]);

  return (
    <ContentWrapper>
      <Container fluid className="min-vh-100">
        <Container fluid>
          <Row>
            <Col xs={12} lg={5} className="min-vh-100 left-panel-border pe-lg-48 py-32 pt-lg-88">
              <Row className="me-24 mb-md-32">
                <Col className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-400 mb-0">Settings</h1>
                </Col>
              </Row>
              {settingTabs && settingTabs.map((obj, idx) => {
                const isAllowed = obj.permissionsRequired?.length ? obj.permissionsRequired.some(requiredPermission => hasPermission(requiredPermission)) : true;
                return (
                  <Card
                    key={idx}
                    className={`${!isAllowed && 'pe-none'} p-16 w-100 h-auto ${currentTab === obj.tab ? 'border border-2 border-info' : 'row-hover-effect border-gray'} ${obj.id !== 1 ? 'mt-24' : ''}`}
                    onClick={() => history.push(`/settings/${obj.route}`)}
                  >
                    <Row>
                      <Col xs={2} className="d-flex justify-content-center">
                        <Image src={obj.icon} height={28} width={28} />
                      </Col>
                      <Col xs={10} className={`ps-0 ${!isAllowed && 'text-gray-200'}`}>
                        <h3 className={`fw-700 text-dark mb-16 ${!isAllowed && 'text-dark'}`}>{obj.title}</h3>
                        <h5 className={`fw-400 text-dark mb-0 ${!isAllowed && 'text-dark'}`}>{obj.description}</h5>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </Col>
            <SettingsModal settingTabs={settingTabs} signal={$settings} />
            <Col lg={7} className="ps-48 mt-88 d-none d-lg-block">
              {settingTabs && settingTabs.map((obj, idx) => {
                if (obj.tab === currentTab) {
                  return (
                    <div key={idx}>
                      {obj.component}
                    </div>
                  );
                }
                return '';
              })}
            </Col>
          </Row>
        </Container>
      </Container>
    </ContentWrapper>
  );
};

export default Settings;

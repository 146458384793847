import { useEffect } from 'react';
import { Button, Col, Dropdown, Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { createRoleArray, sendRemindersArray, updateRoleArray } from 'utils/selectArrays';
import $user from 'signals/User.signals';
import SelectInput from 'components/global/Inputs/SelectInput';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import Loadable from 'components/global/Loadable';
import {
  deleteCollaborator,
  fetchOrganizationCollaborators,
  inviteUserToOrganization,
  updateCollaboratorRole,
  deleteCollaboratorInvite,
  resendInvite,
} from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.helpers';
import { $settingsOrganization, $settingsOrganizationUI, $settingsOrganizationCollaboratorForm } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.signals';
import addUserPlus from 'images/addUserPlus.svg';
import threeVerticalDots from 'images/threeVerticalDots.svg';
import RoundedImage from 'components/global/RoundedImage';
import moment from 'moment';
import useWindowSize from 'hooks/useWindowSize';

const OrganizationUsers = () => {
  const { users, invites } = $settingsOrganization.value;
  const { usersPage } = $settingsOrganizationUI.value;
  const {
    selectedUser,
    newRole,
    newSendInvoiceReminders,
    newUserEmail,
    newUserRole,
  } = $settingsOrganizationCollaboratorForm.value;
  const { user, currentOrganization } = $user.value;
  const { isBreakpointDown } = useWindowSize();
  const isMobile = isBreakpointDown('md');

  useEffect(async () => {
    if (user) {
      await fetchOrganizationCollaborators($user.value.currentOrganization.id);
    }
  }, []);

  return (
    <Row className="mx-0">
      <Row className="mx-0 my-24 py-24 bg-gray-50 rounded">
        <Loadable template="component" signal={$settingsOrganization}>
          <>
            {usersPage === 'users' && (
              <>
                <Row className="mx-0">
                  <Col xs={6} className="p-lg-0">
                    <h2 className="fw-700 mb-0">Users</h2>
                  </Col>
                  <Col xs={6} className="p-lg-0">
                    <div className="d-flex justify-content-lg-end">
                      {currentOrganization?.subscriptionLevel > 1 || users.concat(invites).length < 2 ? (
                        <Button
                          variant="eye"
                          onClick={() => {
                            $settingsOrganizationUI.update({
                              usersPage: 'add',
                            });
                            $settingsOrganizationCollaboratorForm.update({
                              newUserEmail: '',
                              newUserRole: 'Select',
                            });
                          }}
                          className="w-auto mb-16 ms-lg-auto fw-350 d-flex align-items-center"
                        >
                          <Image src={addUserPlus} height="22" width="22" className="me-8" />
                          Add User
                        </Button>
                      ) : (
                        <OverlayTrigger
                          key="overlay-trigger"
                          placement="bottom"
                          overlay={(
                            <Tooltip id="tooltip-bottom" className="d-block">
                              Switch to Paid Plan to add more users
                            </Tooltip>
                          )}
                        >
                          <Button
                            variant="eye"
                            className="w-auto mb-16 ms-auto fw-350 d-flex align-items-center"
                          >
                            <Image src={addUserPlus} height="22" width="22" className="me-8" />
                            Add User
                          </Button>
                        </OverlayTrigger>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mx-0 px-0 border-top border-gray-100 pt-16">
                  {users && users.map((obj, idx) => (
                    <Row className="mx-0 px-0 border-bottom border-gray-100 pb-16 px-8 mb-16" key={idx}>
                      <Col xs={2} sm={2} className="p-0">
                        {obj?.profilePicture ? (
                          <RoundedImage src={obj?.profilePicture} height={isMobile ? '48px' : '68px'} width={isMobile ? '48px' : '68px'} />
                        ) : (
                          <div
                            className="rounded-circle d-flex justify-content-center align-items-center border border-primary position-relative"
                            style={{ height: `${isMobile ? '48px' : '68px'}`, width: `${isMobile ? '48px' : '68px'}` }}
                          >
                            <h2 className="fw-700 mb-0 text-primary position-absolute">{obj.firstName.charAt(0)}{obj.lastName.charAt(0)}</h2>
                          </div>
                        )}
                      </Col>
                      <Col xs={7} sm={6} className="p-0 my-auto ps-8">
                        <h4 className="mb-0 w-100">
                          {obj.firstName}{' '}{obj.lastName}
                        </h4>
                        <small className="m-0 p-0 w-100 text-dark">{obj.email}</small>
                      </Col>
                      <Col xs={2} className="p-0 my-auto">
                        {obj.role === 'OWNER' && (
                          <div>
                            Owner
                          </div>
                        )}
                        {obj.role === 'ADMIN' && (
                          <div>
                            Admin
                          </div>
                        )}
                        {obj.role === 'EDITOR' && (
                          <div>
                            Editor
                          </div>
                        )}
                        {obj.role === 'VIEWER' && (
                          <div>
                            View Only
                          </div>
                        )}
                      </Col>
                      <Col xs={1} sm={2} className="p-0 d-flex align-items-center justify-content-end">
                        {currentOrganization?.subscriptionLevel > 1 ? (
                          <Dropdown
                            className="p-0"
                            onSelect={(e) => {
                              $settingsOrganizationUI.update({
                                usersPage: e,
                              });
                              $settingsOrganizationCollaboratorForm.update({
                                selectedUser: obj,
                                newRole: obj.role,
                                newSendInvoiceReminders: obj.sendInvoiceReminders,
                              });
                            }}
                          >
                            <Dropdown.Toggle data-cy="filter-select" variant="eye" id="dropdown-basic" className="custom-dropdown-toggle border-none w-100 text-start bg-white d-flex align-items-center pe-0">
                              <Image height={20} src={threeVerticalDots} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100">
                              <Dropdown.Item eventKey="edit" className="rounded">Edit</Dropdown.Item>
                              <Dropdown.Item eventKey="users" className="rounded" disabled={obj.role === 'OWNER'} onClick={() => deleteCollaborator(obj.userId, obj.orgId)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <OverlayTrigger
                            key="overlay-trigger"
                            placement="bottom"
                            overlay={(
                              <Tooltip id="tooltip-bottom" className="d-block">
                                Switch to Paid Plan to edit users
                              </Tooltip>
                            )}
                          >
                            <Button
                              variant="eye"
                              className="w-auto mb-16 ms-auto fw-350 d-flex align-items-center"
                            >
                              <Image height={20} src={threeVerticalDots} />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </Col>
                    </Row>
                  ))}
                </Row>
                {!!invites.length && (
                  <Row className="mx-0 px-0 pt-24">
                    <Row className="mx-0 px-8 mb-16">
                      <Col className="p-0">
                        <h4>Pending Invites</h4>
                      </Col>
                    </Row>
                    {invites.map(invite => (
                      <Row className="mx-0 px-0 border-bottom border-gray-100 pb-16 px-8 mb-16" key={invite.email}>
                        <Col sm={2} className="p-0 my-auto text-dark">
                          {moment(invite.createdAt).format('l')}
                        </Col>
                        <Col sm={6} className="p-0 my-auto ps-8">
                          <small className="mb-0 w-100 text-dark">
                            {invite.email}
                          </small>
                        </Col>
                        <Col sm={2} className="p-0 my-auto">
                          {invite.role}
                        </Col>
                        <Col sm={2} className="p-0 d-flex align-items-center justify-content-end">
                          <Dropdown className="p-0">
                            <Dropdown.Toggle variant="eye" id="dropdown-basic" className="custom-dropdown-toggle border-none w-100 text-start bg-white d-flex align-items-center">
                              <Image height={20} src={threeVerticalDots} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100">
                              <Dropdown.Item onClick={() => resendInvite(invite.id)} eventKey="resend" className="rounded">Resend</Dropdown.Item>
                              <Dropdown.Item onClick={() => deleteCollaboratorInvite(invite.id)} eventKey="delete" className="rounded">Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                )}
              </>
            )}
            {usersPage === 'edit' && (
              <>
                <Row className="mx-0 px-0 pb-16 px-8">
                  <Col sm={2} className="p-0">
                    {selectedUser?.profilePicture ? (
                      <RoundedImage src={selectedUser?.profilePicture} height="68px" width="68px" />
                    ) : (
                      <div
                        className="rounded-circle d-flex justify-content-center align-items-center border border-primary position-relative"
                        style={{ height: '68px', width: '68px' }}
                      >
                        <h2 className="fw-700 mb-0 text-primary position-absolute">{selectedUser.firstName.charAt(0)}{selectedUser.lastName.charAt(0)}</h2>
                      </div>
                    )}
                  </Col>
                  <Col sm={10} className="p-0 my-auto ps-8">
                    <h4 className="mb-0 w-100">
                      {selectedUser.firstName}{' '}{selectedUser.lastName}
                    </h4>
                    <small className="m-0 p-0 w-100 text-dark">{selectedUser.email}</small>
                  </Col>
                </Row>
                <Row className="mx-0 px-8">
                  <Col sm={12} className="p-0 my-auto">
                    <Form.Label htmlFor="newRole"><strong>Update Role</strong></Form.Label>
                    <SelectInput
                      name="newRole"
                      options={updateRoleArray}
                      signal={$settingsOrganizationCollaboratorForm}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-8 mt-8">
                  <Col sm={12} className="p-0">
                    <Form.Label htmlFor="newSendInvoiceReminders"><strong>Do you want Invoice Reminders to be sent to this user?</strong></Form.Label>
                    <SelectInput
                      name="newSendInvoiceReminders"
                      options={sendRemindersArray}
                      signal={$settingsOrganizationCollaboratorForm}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-0">
                  <Col className="ps-0">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        $settingsOrganizationUI.update({
                          usersPage: 'users',
                        });
                        $settingsOrganizationCollaboratorForm.update({
                          selectedUser: null,
                          newRole: 'Select',
                          newSendInvoiceReminders: false,
                        });
                      }}
                      className="w-100 mb-16 mt-24"
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col className="pe-0">
                    <Button
                      variant="primary"
                      onClick={() => updateCollaboratorRole(selectedUser.userId, selectedUser.orgId)}
                      className="w-100 mb-16 mt-24"
                      disabled={selectedUser.role === newRole && selectedUser.sendInvoiceReminders === newSendInvoiceReminders}
                    >
                      SAVE CHANGES
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {usersPage === 'add' && (
              <>
                <h2 className="fw-700 mb-8 px-8">Invite with Email</h2>
                <Row className="mx-0 px-8 mt-8">
                  <Col sm={12} className="p-0">
                    <Form.Label htmlFor="newUserEmail"><strong>Email</strong></Form.Label>
                    <UniversalInput
                      placeholder="Email"
                      className="mb-16"
                      name="newUserEmail"
                      signal={$settingsOrganizationCollaboratorForm}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-8">
                  <Col sm={12} className="p-0 my-auto">
                    <Form.Label htmlFor="TeamMember"><strong>Role</strong></Form.Label>
                    <SelectInput
                      name="newUserRole"
                      options={createRoleArray}
                      signal={$settingsOrganizationCollaboratorForm}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-0">
                  <Col className="ps-0">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        $settingsOrganizationCollaboratorForm.update({
                          newUserEmail: '',
                          newUserRole: 'Select',
                        });
                        $settingsOrganizationUI.update({
                          usersPage: 'users',
                        });
                      }}
                      className="w-100 mb-16 mt-24"
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col className="pe-0">
                    <Button
                      variant="primary"
                      onClick={() => inviteUserToOrganization($user.value?.currentOrganization?.id)}
                      className="w-100 mb-16 mt-24"
                      disabled={newUserRole === 'Select' || newUserEmail === '' || $settingsOrganization.value?.isLoading}
                    >
                      SEND INVITE
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        </Loadable>
      </Row>
    </Row>
  );
};

export default OrganizationUsers;
